import { Card } from 'reactstrap';
import { BrowserRouter } from 'react-router-dom';
import { useNavigate } from "react-router"; 
import { OktaAuth, toRelativeUrl } from '@okta/okta-auth-js';
import { Security } from '@okta/okta-react';

import AppRoutes from './main/routes';
import './App.css';

const withRouter = (Component: any) => {
  const Wrapper = (props: any) => {
    const history = useNavigate();
    return <Component history={history} {...props} />;
  };
  return Wrapper;
};

const oktaAuth = new OktaAuth({
  issuer: 'https://netspend-sso.okta.com',
  clientId: '0oadt5kybhbYzJfaR697',
  redirectUri: window.location.origin + '/login/callback'
});

const RouterApp = () => {
  const navigate = useNavigate();
  const restoreOriginalUri = async (_oktaAuth: any, originalUri: string) => {
    navigate(toRelativeUrl(originalUri || '/', window.location.origin), {replace: true});
  }

  return (
      <div className="app-container" >
        <div className="container-fluid view-container" id="app-view-container">
          <Card className="jh-card">
            <Security oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri}>
              <AppRoutes />
            </Security>
          </Card>
        </div>
      </div>
  );
}

const AppWithRouterAccess = withRouter(RouterApp);
const App = () => (<BrowserRouter><AppWithRouterAccess/></BrowserRouter>);

export default App;
